<template>
  <div>
    <v-row>
      <v-col
        xl="3"
        lg="3"
        md="6"
        sm="12"
        xs="12"
        cols="12"
      >
        <v-list-item-subtitle
          class="text-h6 font-weight-light mb-2"
        >
          {{ $i18n.t('$l.screeningitem.organization') }}
        </v-list-item-subtitle>
        <v-list-item-title
          class="text-h5"
          style="white-space: normal;"
        >
          {{ screeningDetailResult.screeningSelectedItem.name }}
        </v-list-item-title>

        <v-list-item-subtitle
          class="text-body-1"
          style="white-space: normal;"
        >
          {{ screeningDetailResult.screeningSelectedItem.address }}
        </v-list-item-subtitle>
        <v-list-item-subtitle
          class="text-body-1 font-weight-medium"
          style="white-space: normal;"
        >
          {{ screeningDetailResult.screeningSelectedItem.ipo }}
        </v-list-item-subtitle>
      </v-col>

      <v-col
        xl="3"
        lg="3"
        md="6"
        sm="12"
        xs="12"
        cols="12"
      >
        <AmlAlerts
          :aml-alerts="screeningDetailResult.basicOrgData.amlAlerts"
        />
      </v-col>

      <v-col
        xl="3"
        lg="3"
        md="6"
        sm="12"
        xs="12"
        cols="12"
      >
        <InvestigationCard
          :redirect-path="'/investigation/person/createCase/LegalEntityCase'"
          asset-image="InvCompany.jpg"
          title="$l.paths.legalEntityInvestigation"
          :card-height="$vuetify.breakpoint.xs ? 120 : 180"
          :create-case="{caseType: 'LegalEntityCase', subject: screeningDetailResult.screeningSelectedItem.name, businessId: screeningDetailResult.screeningSelectedItem.ipo}"
          show-card-hint
        />
      </v-col>

      <v-col
        xl="3"
        lg="3"
        md="6"
        sm="12"
        xs="12"
        cols="12"
      >
        <InvestigationCard
          :redirect-path="'/investigation/transaction/createCase/TransactionCase'"
          asset-image="InvTransaction.jpg"
          title="$l.paths.transactionInvestigation"
          :card-height="$vuetify.breakpoint.xs ? 120 : 180"
          :create-case="{caseType: 'TransactionCase', personType: 'LegalEntity', subject: screeningDetailResult.screeningSelectedItem.name, businessId: screeningDetailResult.screeningSelectedItem.ipo}"
          show-card-hint
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div>
          <v-list-item-subtitle
            class="text-body-2 font-weight-bold mt-3"
            style="text-transform: uppercase;line-height: 1em; white-space: normal;"
          >
            <span
              class="okaml_cursor_pointer"
              @click="showOrganizationDetails = !showOrganizationDetails"
            >
              {{ $i18n.t('$l.screeningitem.organizationDetails') }}
              <v-icon>
                {{ showOrganizationDetails ? 'mdi-close' : 'mdi-arrow-down' }}
              </v-icon>
            </span>
          </v-list-item-subtitle>
        </div>
      </v-col>
    </v-row>

    <v-row
      v-if="showOrganizationDetails"
      class="grey lighten-3"
    >
      <v-col
        xl="4"
        lg="4"
        md="6"
        sm="12"
        xs="12"
        cols="12"
        style="max-width: 340px;"
      >
        <div class="text-h6 font-weight-light">
          {{ $i18n.t('$l.screeningitem.organization') }}
        </div>

        <v-divider class="mb-3" />

        <ResultRespRow
          l="$l.screeningitem.established"
          :r="$utils.formatDateWithoutTime(screeningDetailResult.basicOrgData.established)"
        />

        <ResultRespRow
          l="$l.screeningitem.srcreg"
          :r="screeningDetailResult.basicOrgData.srcreg"
        />

        <ResultRespRow
          v-if="screeningDetailResult.basicOrgData.terminated"
          l="$l.screeningitem.terminated"
          :r="$utils.formatDateWithoutTime(screeningDetailResult.basicOrgData.terminated)"
          :class="{ 'error--text' :screeningDetailResult.basicOrgData.terminated }"
        />

        <ResultRespRow
          v-if="screeningDetailResult.orgNames && screeningDetailResult.orgNames.length > 0"
          l="$l.screeningitem.names"
        >
          <div
            v-for="(name, idx) in screeningDetailResult.orgNames"
            :key="idx"
            class="text-body-2"
            style="width: 100%"
          >
            <ResultRespRow
              :r="name.name"
              class="mt-2"
            >
              <span
                v-if="name.nameto"
                class="text-body-2"
              >
                {{ $i18n.t('$l.app.to') + ' ' + $utils.formatDateWithoutTime(name.nameto) }}
              </span>
            </ResultRespRow>
          </div>
        </ResultRespRow>

        <ResultRespRow
          v-if="screeningDetailResult.orgIpos && screeningDetailResult.orgIpos.length > 0"
          l="$l.screeningitem.ipos"
        >
          <div
            v-for="(ipo, idx) in screeningDetailResult.orgIpos"
            :key="idx"
            class="text-body-2"
            style="width: 100%"
          >
            <ResultRespRow
              :r="ipo.ipo"
              class="mt-2"
            >
              <span
                v-if="ipo.ipoto"
                class="text-body-2"
              >
                {{ $i18n.t('$l.app.to') + ' ' + $utils.formatDateWithoutTime(ipo.ipoto) }}
              </span>
            </ResultRespRow>
          </div>
        </ResultRespRow>

        <ResultRespRow
          v-if="screeningDetailResult.orgForms && screeningDetailResult.orgForms.length > 0"
          l="$l.screeningitem.forms"
        >
          <div
            v-for="(form, idx) in screeningDetailResult.orgForms"
            :key="idx"
            class="text-body-2"
            style="width: 100%"
          >
            <ResultRespRow
              :r="form.form"
              class="mt-2"
            >
              <span
                v-if="form.formto"
                class="text-body-2"
              >
                {{ $i18n.t('$l.app.to') + ' ' + $utils.formatDateWithoutTime(form.formto) }}
              </span>
            </ResultRespRow>
          </div>
        </ResultRespRow>

        <ResultRespRow
          v-if="screeningDetailResult.orgAddress && screeningDetailResult.orgAddress.length > 0"
          l="$l.screeningitem.addresses"
        >
          <div
            v-for="(address, idx) in screeningDetailResult.orgAddress"
            :key="idx"
            class="text-body-2"
            style="width: 100%"
          >
            <ResultRespRow
              :r="address.address"
              class="mt-2"
            >
              <span
                v-if="address.addressto"
                class="text-body-2"
              >
                {{ $i18n.t('$l.app.to') + ' ' + $utils.formatDateWithoutTime(address.addressto) }}
              </span>
            </ResultRespRow>
          </div>
        </ResultRespRow>
      </v-col>

      <v-col
        xl="4"
        lg="4"
        md="6"
        sm="12"
        xs="12"
        cols="12"
        style="max-width: 340px;"
      >
        <div class="text-h6 font-weight-light">
          {{ $i18n.t('$l.screeningitem.orgpersons') }}
          <v-divider class="mb-2" />
        </div>
        <ResultRespRow
          v-if="screeningDetailResult.statutories && screeningDetailResult.statutories.length > 0"
          l="$l.screeningitem.statutories"
        >
          <div
            v-for="(person, idx) in screeningDetailResult.statutories"
            :key="idx"
            class="text-body-2"
            style="width: 100%"
          >
            <ResultRespRow
              :r="person.personname"
            >
              <span
                v-if="person.functionto"
                class="text-body-2"
              >
                {{ $i18n.t('$l.app.to') + ' ' + $utils.formatDateWithoutTime(person.functionto) }}
              </span>
            </ResultRespRow>
          </div>
        </ResultRespRow>

        <ResultRespRow
          v-if="screeningDetailResult.stakeholders && screeningDetailResult.stakeholders.length > 0"
          l="$l.screeningitem.stakeholders"
        >
          <div
            v-for="(person, idx) in screeningDetailResult.stakeholders"
            :key="idx"
            class="text-body-2"
            style="width: 100%"
          >
            <ResultRespRow
              :r="person.personname"
            >
              <span
                v-if="person.functionto"
                class="text-body-2"
              >
                {{ $i18n.t('$l.app.to') + ' ' + $utils.formatDateWithoutTime(person.functionto) }}
              </span>
            </ResultRespRow>
          </div>
        </ResultRespRow>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ResultRespRow from './ResultRespRow.vue'
import AmlAlerts from './AmlAlerts.vue'
import InvestigationCard from '@/components/investigation/InvestigationCard.vue'

export default {
  name: 'ScreeningSearchResultDetailOrganization',
  components: {
    ResultRespRow,
    AmlAlerts,
    InvestigationCard
  },
  props: {
    screeningDetailResult: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showOrganizationDetails: false
    }
  },
  created () {
    let set = new Set()
    this.screeningDetailResult.orgNames.forEach(e => {
      e.id = 0
      set.add(JSON.stringify(e))
    })
    this.screeningDetailResult.orgNames = Array.from(set, e => JSON.parse(e))
    this.screeningDetailResult.orgNames = this.screeningDetailResult.orgNames.sort((a, b) => (a.nameto > b.nameto) ? 1 : -1)
    this.screeningDetailResult.orgNames = this.screeningDetailResult.orgNames.sort((a, b) => !a.nameto ? -2 : 0)

    set = new Set()
    this.screeningDetailResult.orgIpos.forEach(e => {
      e.id = 0
      set.add(JSON.stringify(e))
    })
    this.screeningDetailResult.orgIpos = Array.from(set, e => JSON.parse(e))
    this.screeningDetailResult.orgIpos = this.screeningDetailResult.orgIpos.sort((a, b) => (a.ipoto > b.ipoto) ? 1 : -1)
    this.screeningDetailResult.orgIpos = this.screeningDetailResult.orgIpos.sort((a, b) => !a.ipoto ? -2 : 0)

    set = new Set()
    this.screeningDetailResult.orgForms.forEach(e => {
      e.id = 0
      set.add(JSON.stringify(e))
    })
    this.screeningDetailResult.orgForms = Array.from(set, e => JSON.parse(e))
    this.screeningDetailResult.orgForms = this.screeningDetailResult.orgForms.sort((a, b) => (a.formto > b.formto) ? 1 : -1)
    this.screeningDetailResult.orgForms = this.screeningDetailResult.orgForms.sort((a, b) => !a.formto ? -2 : 0)

    this.screeningDetailResult.personRecords = this.screeningDetailResult.personRecords.sort((a, b) => (a.functionto < b.functionto) ? 1 : -1)
    this.screeningDetailResult.personRecords = this.screeningDetailResult.personRecords.sort((a, b) => !a.functionto ? -2 : 0)

    this.screeningDetailResult.statutories = this.screeningDetailResult.personRecords.filter(p => (p.persontype === 'statutory' && !p.functionto))

    this.screeningDetailResult.stakeholders = this.screeningDetailResult.personRecords.filter(p => (p.persontype === 'stakeholder' && !p.functionto))

    set = new Set()
    this.screeningDetailResult.personRecords.forEach(e => {
      set.add(e.personfunction)
    })
  }
}
</script>
