<template>
  <v-container
    fluid
  >
    <basic-card
      title="$l.paths.screening"
      class="mt-9"
    >
      <div
        class="my-3"
        style="border: 1px solid grey; max-width: 30em; border-radius: 4px;"
      >
        <SearchAnyPerson
          id="screeningSearchItemsMenuAnchor"
          class="mx-0 white"
          style="width: 100%; border-radius: 4px; overflow: hidden;"
        />
      </div>

      <app-api-call
        class="my-3 mb-0"
        :call-result="callResult"
      />

      <v-row
        class="ma-0 pa-0 justify-center"
      >
        <transition
          mode="out-in"
          :css="false"
          @before-enter="beforeEnter"
          @enter="enter"
          @leave="leave"
        >
          <div
            v-if="screeningDetailResult.screeningSelectedItem"
          >
            <ScreeningSearchResultDetail
              :screening-detail-result="screeningDetailResult"
            />
          </div>
        </transition>
      </v-row>

      <v-row
        class="ma-0 pa-0 justify-center"
      >
        <transition
          mode="out-in"
          :css="false"
          @before-enter="beforeEnter"
          @enter="enter"
          @leave="leave"
        >
          <div
            v-if="screeningViewShowSearchItems"
          >
            <ScreeningSearchItemsWrapper
              :screening-search-items="screeningSearchItems"
              :screening-search-items-wrapper-params="screeningSearchItemsWrapperParams"
              @redirect-to-screening="redirectToScreening"
              @confirm-request-params-enabled="setConfirmRequestParamsEnabled"
              @screening-re-init="init()"
            />
          </div>
        </transition>
      </v-row>
    </basic-card>
  </v-container>
</template>

<script>
import Velocity from 'velocity-animate'

import SearchAnyPerson from '@/components/screening/SearchAnyPerson.vue'
import ScreeningSearchResultDetail from '@/components/screening/ScreeningSearchResultDetail.vue'
import ScreeningSearchItemsWrapper from '@/components/screening/ScreeningSearchItemsWrapper.vue'

export default {
  name: 'ScreeningWrapper',
  components: {
    SearchAnyPerson,
    ScreeningSearchResultDetail,
    ScreeningSearchItemsWrapper
  },
  data () {
    return {
      callResult: { finished: true, authorized: false, error: null, info: null },
      screeningDetailResult: {},
      screeningViewShowSearchItems: false,
      screeningSearchItems: [],
      screeningSearchItemsWrapperParams: {
        maxResultsSlider: { val: 60, color: 'primary' },
        similaritySlider: { val: 0.35, color: 'info' },
        showMaxResultsWarning: false,
        confirmRequestParamsEnabled: false
      }
    }
  },
  computed: {
    screeningSelectedItem: {
      get () {
        return this.$store.getters.getScreeningSelectedItem
      },
      set (val) {
        this.$store.commit('SET_SCREENING_SELECTED_ITEM', val)
      }
    }
  },
  watch: {
    screeningSelectedItem (val) {
      this.init()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.callResult.error = null
      this.screeningDetailResult = {}
      this.screeningViewShowSearchItems = false
      this.screeningSearchItems = []
      if (this.screeningSelectedItem.id >= 0) {
        if (this.screeningSelectedItem.id === 0) {
          this.screeningDetailResult.screeningSelectedItem = this.screeningSelectedItem
        } else {
          this.screeningLoadDetais()
        }
      } else {
        if (this.$store.getters.getScreeningSearchObject.searchPhrase) {
          this.screeningSearchExtended()
        }
      }
    },
    screeningLoadDetais () {
      this.callResult.finished = false
      this.callResult.info = this.$i18n.t('$l.screening.inProgressResultData')
      this.$xapi.post('endpointsScreeningSearch/dsorgandpersonId', this.screeningSelectedItem)
        .then(r => {
          this.screeningDetailResult = r.data
          this.screeningDetailResult.screeningSelectedItem = this.screeningSelectedItem
        })
        .finally(() => {
          this.callResult.info = null
          this.callResult.finished = true
        })
    },
    screeningSearchExtended () {
      this.screeningSearchItemsWrapperParams.showMaxResultsWarning = false
      this.callResult.finished = false
      this.callResult.info = this.$i18n.t('$l.screening.inProgressExtendedSearch')
      this.screeningSearchItemsWrapperParams.confirmRequestParamsEnabled = false
      let clone = JSON.parse(JSON.stringify(this.$store.getters.getScreeningSearchObject))
      clone.maxResults = this.screeningSearchItemsWrapperParams.maxResultsSlider.val
      clone.similarityThreshold = this.screeningSearchItemsWrapperParams.similaritySlider.val
      this.$store.commit('SET_SCREENING_SEARCH_OBJECT', clone)
      this.$xapi.post('endpointsScreeningSearch/businessIdOrNameExtended', this.$store.getters.getScreeningSearchObject)
        .then(r => {
          this.screeningSearchItems = r.data
          if (this.screeningSearchItemsWrapperParams.maxResultsSlider.val === this.screeningSearchItems.length) {
            this.screeningSearchItemsWrapperParams.showMaxResultsWarning = true
          }
        })
        .catch(e => {
          if (e.response.status === 504) {
            this.callResult.error = this.$i18n.t('$l.screening.timeoutAdjustParams')
          } else {
            this.callResult.error = e.response.localizedMessage
          }
        })
        .finally(() => {
          this.callResult.info = null
          this.callResult.finished = true
          this.screeningViewShowSearchItems = true
        })
    },
    resetScreeningSearch () {
      this.$store.commit('SET_SCREENING_SEARCH_OBJECT', {})
    },
    redirectToScreening (item) {
      this.$store.commit('SET_SCREENING_SELECTED_ITEM', item)
      this.screeningSearchItems = []
    },
    setConfirmRequestParamsEnabled (val) {
      this.screeningSearchItemsWrapperParams.confirmRequestParamsEnabled = val
    },
    beforeEnter (el) {
      el.style.opacity = 0
      el.style.width = '60%'
      el.style.overflow = 'hidden'
    },
    enter (el, done) {
      Velocity(el,
        { opacity: 1, width: '100%' },
        { duration: 700, easing: 'easeIn', complete: done })
    },
    leave (el, done) {
      Velocity(el,
        { opacity: 0.6, height: '1px' },
        { duration: 500, easing: 'easeOut', complete: done })
    }
  }
}
</script>
