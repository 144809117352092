var caseMethods = {

  createLeghalEntityParticipant (createCase, participantDefault) {
    let participant = JSON.parse(JSON.stringify(participantDefault))
    participant.name = createCase.subject
    participant.formData.subject = createCase.subject
    participant.formData.businessId = createCase.businessId
    participant.type = 'LegalEntity'
    return participant
  },
  createPhysicalPersonParticipant (createCase, participantDefault) {
    let participant = JSON.parse(JSON.stringify(participantDefault))
    participant.name = createCase.subject
    participant.formData.subject = createCase.subject
    participant.formData.birthDate = createCase.birthDate
    participant.formData.idCardNumber = createCase.idCardNumber
    participant.formData.idCardType = createCase.idCardType
    participant.type = 'PhysicalPerson'

    return participant
  },
  createCase (createCase, participantDefault) {
    let newCase = {
      caseId: '',
      type: '',
      userName: '',
      name: '',
      description: '',
      status: '',
      result: {},
      created: '',
      lastModified: '',
      transactionValue: '',
      transactionCurrency: '',
      transactionPredictedDate: '',
      notes: [],
      participants: []
    }
    newCase.type = createCase.caseType
    newCase.name = createCase.subject
    if (createCase.caseType === 'TransactionCase') {
      newCase.transactionValue = 0
      newCase.transactionCurrency = 'EUR'
      newCase.transactionPredictedDate = new Date().toISOString().substring(0, 10)
      if (createCase.personType === 'LegalEntity') {
        newCase.participants.push(caseMethods.createLeghalEntityParticipant(createCase, participantDefault))
      }
      if (createCase.personType === 'PhysicalPerson') {
        newCase.participants.push(caseMethods.createPhysicalPersonParticipant(createCase, participantDefault))
      }
    }
    if (createCase.caseType === 'PhysicalPersonCase') {
      newCase.participants.push(caseMethods.createPhysicalPersonParticipant(createCase, participantDefault))
    }
    if (createCase.caseType === 'LegalEntityCase') {
      newCase.participants.push(caseMethods.createLeghalEntityParticipant(createCase, participantDefault))
    }
    return newCase
  }
}

export default caseMethods
