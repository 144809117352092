<template>
  <div
    @mouseover="hoverCard = true"
    @mouseout="hoverCard = false"
  >
    <app-api-call
      :call-result="callResult"
    />

    <div
      v-if="callResult.finished"
    >
      <div v-if="invActivationData.suspended">
        <v-card
          class="fade mx-auto"
          flat
        >
          <v-img
            class="white--text"
            :height="(cardHeight - 82) + 'px'"
            :src="require(`@/assets/${assetImage}`)"
          >
            <div class="opacity">
              <v-card-title class="">
                {{ title | translate }}
              </v-card-title>
            </div>
          </v-img>
          <basic-service-required
            v-if="invActivationData.suspendedActivation"
            title="$l.services.suspendedActivation"
            dclass="greyee error--text"
            label="$l.services.renewPackage"
          />
          <basic-service-required
            v-if="invActivationData.suspendedInvestigations"
            title="$l.services.suspendedInvestigations"
            dclass="greyee error--text"
            push-to="/services/investigation"
            label="$l.services.addInvestigations"
          />
        </v-card>
      </div>
      <div v-else>
        <v-hover v-slot:default="{ hover }">
          <v-card
            class="fade mx-auto"
            :elevation="hover ? 6 : 0"
            @click="redirectToCase()"
          >
            <v-img
              class="white--text"
              :height="cardHeight + 'px'"
              :src="require(`@/assets/${assetImage}`)"
            >
              <div class="opacity">
                <v-card-title class="">
                  {{ title | translate }}
                </v-card-title>
              </div>
            </v-img>
          </v-card>
        </v-hover>
      </div>
    </div>
    <div v-else>
      <v-card
        class="fade mx-auto"
        flat
      >
        <v-img
          class="white--text"
          :height="cardHeight + 'px'"
          :src="require(`@/assets/${assetImage}`)"
        >
          <div class="opacity">
            <v-card-title class="">
              {{ title | translate }}
            </v-card-title>
          </div>
        </v-img>
      </v-card>
    </div>

    <v-card
      v-if="hoverCard && showCardHint"
      outlined
    >
      <v-card-subtitle
        class="white info1--text pa-1"
      >
        <span v-if="assetImage === 'InvCompany.jpg'">{{ $i18n.t('$l.screening.hintInvestigateOrganization') }}</span>
        <span v-if="assetImage === 'InvPerson.jpg'">{{ $i18n.t('$l.screening.hintInvestigatePerson') }}</span>
        <span v-if="assetImage === 'InvTransaction.jpg'">{{ $i18n.t('$l.screening.hintInvestigationBusinessCase') }}</span>
      </v-card-subtitle>
    </v-card>

    <basic-dialog
      v-if="createCase"
      v-model="showConfirmCreateCase"
      title="$l.app.warning"
      save-button
      save-button-label="$l.app.confirm"
      @close-dialog="showConfirmCreateCase = false"
      @save-dialog="doCreateCase()"
    >
      <v-container>
        {{ $i18n.t('$l.screening.confirmCaseCreation') + ' ' }}
        <span
          class="font-weight-medium"
        >{{ createCase.subject }}</span>
      </v-container>
    </basic-dialog>
  </div>
</template>

<script>
import caseMethods from '@/methods/case.js'
export default {
  name: 'InvestigationCard',
  props: {
    redirectPath: {
      type: String,
      required: true
    },
    assetImage: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    cardHeight: {
      type: Number,
      default: 200
    },
    createCase: {
      type: Object,
      default: null
    },
    showCardHint: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      callResult: { finished: false, authorized: true, error: null, info: null },
      invActivationData: { suspended: true, suspendedActivation: true, suspendedInvestigations: true },
      hoverCard: false,
      showConfirmCreateCase: false
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.callResult.finished = false
      this.callResult.error = null
      this.callResult.info = this.$i18n.t('$l.info.checkingRights')
      this.$xapi.get('endpointsUserProfile/getActivationData')
        .then(r => {
          this.invActivationData = r.data
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.finished = true
          this.callResult.info = null
        })
    },
    redirectToCase () {
      if (this.createCase) {
        this.showConfirmCreateCase = true
      } else {
        this.$router.push(this.redirectPath)
      }
    },
    doCreateCase () {
      this.callResult.finished = false
      this.callResult.error = null
      let newCase = caseMethods.createCase(this.createCase, this.$utils.participantDefault)
      this.$xapi.put('case/create', newCase)
        .then(response => {
          if (response.data.type === 'TransactionCase') {
            this.$router.push({ name: 'ProcessCase', params: { caseId: response.data.caseId } })
          } else {
            this.$router.push({ name: 'ProcessCasePerson', params: { caseId: response.data.caseId } })
          }
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.finished = true
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  a {
    text-decoration: none
  }
  .opacity {
    z-index: -1;
    background: rgb(0, 0, 0); /* Fallback color */
    background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  }
  .fade {
    opacity:0.8;
    transition: all .5s ease;
  }
  .fade:hover {
    opacity:1;
  }
</style>
