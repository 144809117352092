<template>
  <v-card
    outlined
    class="pa-3 py-0"
    :style="{'background-color' : getAmlCardColor()}"
  >
    <ScreeningSearchResultDetailOrganization
      v-if="getPersonType === 'TP_ORG'"
      :screening-detail-result="screeningDetailResult"
    />

    <ScreeningSearchResultDetailPerson
      v-if="getPersonType === 'TP_PERS'"
      :screening-detail-result="screeningDetailResult"
    />

    <ScreeningSearchResultDetailSearchPhrase
      v-if="getPersonType === 'TP_PHRASE'"
      :screening-detail-result="screeningDetailResult"
    />
  </v-card>
</template>

<script>
import ScreeningSearchResultDetailOrganization from './ScreeningSearchResultDetailOrganization.vue'
import ScreeningSearchResultDetailPerson from './ScreeningSearchResultDetailPerson.vue'
import ScreeningSearchResultDetailSearchPhrase from './ScreeningSearchResultDetailSearchPhrase.vue'

export default {
  name: 'ScreeningSearchResultDetail',
  components: {
    ScreeningSearchResultDetailOrganization,
    ScreeningSearchResultDetailPerson,
    ScreeningSearchResultDetailSearchPhrase
  },
  props: {
    screeningDetailResult: {
      type: Object,
      required: true
    }
  },
  computed: {
    getPersonType () {
      if (this.screeningDetailResult.basicOrgData) {
        return 'TP_ORG'
      }
      if (this.screeningDetailResult.basicPersonData && this.screeningDetailResult.screeningSelectedItem.id > 0) {
        return 'TP_PERS'
      }
      if (this.screeningDetailResult.screeningSelectedItem.id === 0) {
        return 'TP_PHRASE'
      }
      return null
    }
  },
  methods: {
    getAmlCardColor () {
      if (this.getPersonType === 'TP_ORG') {
        return '#f0ffee'
      }
      if (this.getPersonType === 'TP_PERS') {
        return '#eefeff'
      }
      if (this.getPersonType === 'TP_PHRASE') {
        return '#f0f0f0'
      }
    }
  }
}
</script>
