<template>
  <v-row>
    <v-col
      xl="3"
      lg="3"
      md="6"
      sm="12"
      xs="12"
      cols="12"
    >
      <v-list-item-subtitle
        class="text-h6 font-weight-light mb-2"
      >
        {{ $i18n.t('$l.screeningitem.searchPhrase') }}
      </v-list-item-subtitle>
      <v-list-item-title
        class="text-h5"
        style="white-space: normal;"
      >
        {{ screeningDetailResult.screeningSelectedItem.name }}
      </v-list-item-title>
    </v-col>

    <v-col
      xl="3"
      lg="3"
      md="6"
      sm="12"
      xs="12"
      cols="12"
    >
      <InvestigationCard
        :redirect-path="'/investigation/person/createCase/PhysicalPersonCase'"
        asset-image="InvPerson.jpg"
        title="$l.paths.personInvestigation"
        :card-height="$vuetify.breakpoint.xs ? 120 : 180"
        :create-case="{caseType: 'PhysicalPersonCase', subject: screeningDetailResult.screeningSelectedItem.name}"
      />
    </v-col>

    <v-col
      xl="3"
      lg="3"
      md="6"
      sm="12"
      xs="12"
      cols="12"
    >
      <InvestigationCard
        :redirect-path="'/investigation/person/createCase/LegalEntityCase'"
        asset-image="InvCompany.jpg"
        title="$l.paths.legalEntityInvestigation"
        :card-height="$vuetify.breakpoint.xs ? 120 : 180"
        :create-case="{caseType: 'LegalEntityCase', subject: screeningDetailResult.screeningSelectedItem.name}"
      />
    </v-col>

    <v-col
      xl="3"
      lg="3"
      md="6"
      sm="12"
      xs="12"
      cols="12"
    >
      <InvestigationCard
        :redirect-path="'/investigation/transaction/createCase/TransactionCase'"
        asset-image="InvTransaction.jpg"
        title="$l.paths.transactionInvestigation"
        :card-height="$vuetify.breakpoint.xs ? 120 : 180"
      />
    </v-col>
  </v-row>
</template>

<script>
import InvestigationCard from '@/components/investigation/InvestigationCard.vue'

export default {
  name: 'ScreeningSearchResultDetailSearchPhrase',
  components: {
    InvestigationCard
  },
  props: {
    screeningDetailResult: {
      type: Object,
      required: true
    }
  }
}
</script>
