<template>
  <div>
    <v-row>
      <v-col
        xl="3"
        lg="3"
        md="6"
        sm="12"
        xs="12"
        cols="12"
      >
        <v-list-item-subtitle
          class="text-h6 font-weight-light mb-2"
        >
          {{ $i18n.t('$l.screeningitem.person') }}
        </v-list-item-subtitle>
        <v-list-item-title
          class="text-h5"
          style="white-space: normal;"
        >
          {{ screeningDetailResult.screeningSelectedItem.name }}
        </v-list-item-title>

        <v-list-item-subtitle
          class="text-body-1"
          style="white-space: normal;"
        >
          {{ screeningDetailResult.screeningSelectedItem.address }}
        </v-list-item-subtitle>
        <v-list-item-subtitle
          class="text-body-1 font-weight-medium"
          style="white-space: normal;"
        >
          {{ screeningDetailResult.basicPersonData.name }}
        </v-list-item-subtitle>
      </v-col>

      <v-col
        xl="3"
        lg="3"
        md="6"
        sm="12"
        xs="12"
        cols="12"
      >
        <AmlAlerts
          :aml-alerts="screeningDetailResult.basicPersonData.amlAlerts"
        />
      </v-col>

      <v-col
        xl="3"
        lg="3"
        md="6"
        sm="12"
        xs="12"
        cols="12"
      >
        <InvestigationCard
          :redirect-path="'/investigation/person/createCase/PhysicalPersonCase'"
          asset-image="InvPerson.jpg"
          title="$l.paths.personInvestigation"
          :card-height="$vuetify.breakpoint.xs ? 120 : 180"
          :create-case="{caseType: 'PhysicalPersonCase', subject: screeningDetailResult.screeningSelectedItem.name}"
          show-card-hint
        />
      </v-col>

      <v-col
        xl="3"
        lg="3"
        md="6"
        sm="12"
        xs="12"
        cols="12"
      >
        <InvestigationCard
          :redirect-path="'/investigation/transaction/createCase/TransactionCase'"
          asset-image="InvTransaction.jpg"
          title="$l.paths.transactionInvestigation"
          :card-height="$vuetify.breakpoint.xs ? 120 : 180"
          :create-case="{caseType: 'TransactionCase', personType: 'PhysicalPerson', subject: screeningDetailResult.screeningSelectedItem.name}"
          show-card-hint
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-list-item-subtitle
          class="text-body-2 font-weight-bold mt-3"
          style="text-transform: uppercase;line-height: 1em; white-space: normal;"
        >
          <span
            class="okaml_cursor_pointer"
            @click="showAllConnectedOrganizations = !showAllConnectedOrganizations"
          >
            {{ $i18n.t('$l.screeningitem.organizationsWithThisPerson') }}
            <v-icon>
              {{ showAllConnectedOrganizations ? 'mdi-close' : 'mdi-arrow-down' }}
            </v-icon>
          </span>
        </v-list-item-subtitle>
      </v-col>
    </v-row>

    <v-row
      v-if="showAllConnectedOrganizations"
      class="grey lighten-3"
    >
      <v-col>
        <v-card
          class="pa-3 mb-3"
          flat
        >
          <v-card-subtitle
            class="text-body-1 pa-0 mb-3"
          >
            {{ $i18n.t('$l.screening.organizatinsWithPersonInstruction') }}
          </v-card-subtitle>
          <v-text-field
            v-model="addressSubstring"
            dense
            outlined
            @input="filterConnectedOrgs()"
          >
            Adresa
          </v-text-field>
        </v-card>
        <div
          v-if="!connectedOrgs"
        >
          {{ $i18n.t('$l.app.noDataAvailable') }}
        </div>
        <div
          v-for="(item, idx) in connectedOrgs"
          :key="idx"
          class="d-inline"
        >
          <span
            class="font-weight-medium"
          >
            <div
              class="d-inline-block"
              style="width: 24px;"
            >{{ (idx + 1) + '.' }}</div>
            {{ item.name + ' ' + item.ipo }}
          </span>
          <div
            class="pl-7"
          >
            <div
              v-for="(person, idx2) in item.orgPersons"
              :key="idx2"
            >
              <span
                v-if="person.functionTo"
                class="error--text"
              >{{ person.name + ', ' + person.address + ' (' + person.functionTo + ')' }}</span>
              <span
                v-else
              >{{ person.name + ', ' + person.address }}</span>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AmlAlerts from './AmlAlerts.vue'
import InvestigationCard from '@/components/investigation/InvestigationCard.vue'

export default {
  name: 'ScreeningSearchResultDetailPerson',
  components: {
    AmlAlerts,
    InvestigationCard
  },
  props: {
    screeningDetailResult: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showAllConnectedOrganizations: false,
      addressSubstring: '',
      connectedOrgs: []
    }
  },
  computed: {
    allConnectedOrgs () {
      return this.screeningDetailResult.connectedOrgs
    }
  },
  created () {
    this.connectedOrgs = this.allConnectedOrgs
  },
  methods: {
    filterConnectedOrgs () {
      if (this.addressSubstring.length === 0) {
        this.connectedOrgs = this.allConnectedOrgs
        return
      }
      this.connectedOrgs = this.allConnectedOrgs.filter(o => {
        let adressContainsSubstring = false
        o.orgPersons.forEach(p => {
          adressContainsSubstring |= p.address.toLowerCase().indexOf(this.addressSubstring.toLowerCase()) !== -1
        })
        return adressContainsSubstring
      })
    }
  }
}
</script>
