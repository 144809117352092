<template>
  <ScreeningWrapper />
</template>

<script>
import ScreeningWrapper from '@/components/screening/ScreeningWrapper'

export default {
  name: 'Screening',
  components: {
    ScreeningWrapper
  }
}
</script>
