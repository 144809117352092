<template>
  <div class="px-3">
    <v-row
      class="justify-end body-1 mb-2"
    >
      <div
        :style="{ width: '100%'}"
        class="font-weight-bold body-2"
      >
        <span>{{ $i18n.t(l) }}</span>

        <div
          v-if="flagobject !== null"
          class="ml-2 justify-end float-right font-weight-medium"
        >
          <div
            class="font-weight-bold"
            :class="[flagobject.color]"
          >
            <span v-if="flagobject.tf === true">{{ $i18n.t('$l.app.ynYes').toUpperCase() }}</span>
            <span v-else>{{ $i18n.t('$l.app.ynNo').toUpperCase() }}</span>
          </div>
        </div>

        <div
          v-else
          class="ml-2 justify-end float-right body-1"
          style="line-height: 1.1em;"
        >
          {{ rs }}
        </div>
      </div>

      <slot />
    </v-row>
    <v-divider
      v-if="border"
      class="ma-0 pa-0"
    />
  </div>
</template>

<script>
export default {
  name: 'ResultRespRow',
  props: {
    l: {
      type: String,
      default: ''
    },
    r: {
      type: [String, Number],
      default: ''
    },
    flagobject: {
      type: Object,
      default: null
    },
    border: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    rs () {
      return this.r.toString()
    }
  }
}
</script>
