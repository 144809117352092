<template>
  <v-card
    outlined
  >
    <v-list-item
      style="background-color: #f0f0f0;"
    >
      <v-list-item-avatar
        class="ma-0 pa-0"
      >
        <v-icon
          class="primary--text text--darken-1"
          x-large
        >
          mdi-search-web
        </v-icon>
      </v-list-item-avatar>

      <v-list-item-content
        class="ma-3 my-0"
      >
        <v-list-item-subtitle
          class="text-h5 mb-2"
        >
          {{ $store.getters.getScreeningSearchObject.searchPhrase }}
        </v-list-item-subtitle>

        <v-list-item-subtitle
          class="primary--text text--darken-1 font-weight-medium text-body-1"
          style="line-height: 1em; white-space: normal;"
        >
          {{ $i18n.t('$l.screening.searchItemSelectRecord') }}
        </v-list-item-subtitle>

        <v-list-item-subtitle
          class="ma-0 pa-0 mt-3 info1--text text--darken-1 text-end"
          style="line-height: 1em; white-space: normal;"
        >
          {{ $i18n.t('$l.screening.noExpectedResults') }}
        </v-list-item-subtitle>

        <v-list-item-subtitle
          class="text-body-2 mt-2 font-weight-bold info1--text text-end"
          style="text-transform: uppercase;line-height: 1em; white-space: normal;"
        >
          <span
            class="okaml_cursor_pointer"
            @click="showAmlOptions"
          >
            {{ $i18n.t('$l.screening.showOptionForSearchPhrase') }}
          </span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: 'ScreeningSearchItemsSelectRecord',
  methods: {
    showAmlOptions () {
      this.$store.commit('SET_SCREENING_SELECTED_ITEM', { id: 0, name: this.$store.getters.getScreeningSearchObject.searchPhrase })
    }
  }
}
</script>
