<template>
  <div>
    <v-row
      class="justify-end ma-0 mb-3"
    >
      <v-col
        class="pa-0"
      >
        <div
          class="text-h6 font-weight-light"
          style="width: 100%"
        >
          {{ $i18n.t('$l.screeningitem.amlIndicators') }}
          <v-tooltip
            v-model="showAmlAlertSignalsTooltip"
            bottom
            close-delay="400"
            :open-on-hover="false"
            :open-on-focus="false"
          >
            <template v-slot:activator="{}">
              <v-icon
                @click="showAmlAlertSignalsTooltip = !showAmlAlertSignalsTooltip"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <div
              style="max-width: 300px;"
            >
              {{ $i18n.t('$l.screening.showAmlAlertSignalsTooltip') }}
            </div>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>

    <div
      v-for="(amlAlert, idx) in amlAlerts"
      :key="idx"
    >
      <v-list-item-subtitle
        class="text-body-2 font-weight-medium mt-1"
        :class="{'error--text': amlAlert.warning, 'success--text': !amlAlert.warning}"
        @click="switchShowAmlAlertDescription(idx)"
      >
        <div
          class="okaml_cursor_pointer"
          style="white-space: normal;"
        >
          <span
            style="text-transform: uppercase; white-space: normal;"
          >
            {{ $i18n.t(`$l.amlAlerts.${amlAlert.code}`) }}
          </span>
          <span
            style="text-transform: uppercase; white-space: normal;"
          >
            <span
              class="ml-3"
              :class="{'error--text': amlAlert.warning, 'success--text': !amlAlert.warning}"
              style="text-transform: uppercase; white-space: nowrap;"
            >
              {{ amlAlert.warning ? $i18n.t('$l.app.yes') : $i18n.t('$l.app.no') }}
            </span>
            <v-icon
              small
            >
              {{ showAmlAlertDescription[idx] ? 'mdi-close' : 'mdi-arrow-down' }}
            </v-icon>
          </span>

          <div
            v-if="amlAlert.value"
            class="ma-0 pa-0"
          >
            {{ '(' + $i18n.t('$l.app.result') + ' ' + amlAlert.value + ')' }}
          </div>

          <v-list-item
            v-if="showAmlAlertDescription[idx]"
            class="ma-0 pa-0 mb-1 text-caption"
          >
            {{ $i18n.t(`$l.amlAlerts.${amlAlert.code}_DESC`) }}
          </v-list-item>
          <v-divider class="my-1" />
        </div>
      </v-list-item-subtitle>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AmlAlerts',
  props: {
    amlAlerts: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      showAmlAlertSignalsTooltip: false,
      showAmlAlertDescription: {}
    }
  },
  methods: {
    switchShowAmlAlertDescription (idx) {
      this.showAmlAlertDescription[idx] ? this.showAmlAlertDescription[idx] = !this.showAmlAlertDescription[idx] : this.showAmlAlertDescription[idx] = true
      this.showAmlAlertDescription = JSON.parse(JSON.stringify(this.showAmlAlertDescription))
    }
  }
}
</script>
