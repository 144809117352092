<template>
  <v-row
    class=""
  >
    <v-col
      cols="12"
      order="first"
    >
      <ScreeningSearchItemsSelectRecord
        @screening-re-init="$emit('screening-re-init')"
      />
    </v-col>

    <v-col
      xl="6"
      lg="6"
      md="6"
      sm="12"
      xs="12"
      cols="12"
      style="max-height: 400px; overflow-y: scroll; border: 1px solid #ddd; border-radius: 4px;"
      class="ma-0 pa-0 pl-3"
    >
      <ScreeningSearchItems
        :screening-search-items="screeningSearchItems"
        @redirect-to-screening="redirectToScreening"
      />
    </v-col>

    <v-col
      xl="6"
      lg="6"
      md="6"
      sm="12"
      xs="12"
      cols="12"
      class="text-h6 ma-0 pa-0"
      :order="$vuetify.breakpoint.smAndDown ? 'first' : 'last'"
    >
      <v-card
        outlined
        class="ma-0 pa-3 pb-0"
        :class="$vuetify.breakpoint.smAndDown ? 'ma-3' : 'mx-3'"
      >
        <v-row>
          <v-col>
            {{ screeningSearchItems.length + ' ' + $i18n.t('$l.screening.records') }}

            <v-btn
              class="primary float-right theme--dark"
              :disabled="!screeningSearchItemsWrapperParams.confirmRequestParamsEnabled"
              text
              @click="$emit('screening-re-init')"
            >
              {{ $i18n.t('$l.app.search') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-radio-group
          v-model="filterIcoGroup"
          class="ma-0 pa-0"
        >
          <v-radio
            :label="$i18n.t('$l.screening.recordsBothWithIdOrNoId')"
          />
          <v-radio
            :label="$i18n.t('$l.screening.recordsWithId')"
          />
          <v-radio
            :label="$i18n.t('$l.screening.recordsWithNoId')"
          />
        </v-radio-group>

        <v-slider
          v-model="screeningSearchItemsWrapperParams.maxResultsSlider.val"
          :label="$i18n.t('$l.screening.maxResults')"
          :thumb-color="screeningSearchItemsWrapperParams.maxResultsSlider.color"
          :min="20"
          :max="300"
          step="20"
          thumb-label="always"
          @change="$emit('confirm-request-params-enabled', true)"
        />

        <v-slider
          v-model="screeningSearchItemsWrapperParams.similaritySlider.val"
          :label="$i18n.t('$l.screening.similarityThreshold')"
          :thumb-color="screeningSearchItemsWrapperParams.similaritySlider.color"
          :min="0.2"
          :max="1"
          :step="0.05"
          thumb-label="always"
          class="mt-3"
          @change="$emit('confirm-request-params-enabled', true)"
        />

        <div
          v-if="screeningSearchItemsWrapperParams.showMaxResultsWarning"
          class="warning--text body-2 mb-2"
        >
          {{ $i18n.t('$l.screening.tooMuchRecordsTryFiltering') }}
        </div>

        <v-text-field
          v-model="filterAddressSubstring"
          type="text"
          :placeholder="$i18n.t('$l.screening.filterAddressContains')"
          outlined
          dense
          append-icon="mdi-close"
          @click:append="resetFilterAddress()"
          @keydown.enter="screeningReInit()"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ScreeningSearchItems from '@/components/screening/ScreeningSearchItems.vue'
import ScreeningSearchItemsSelectRecord from '@/components/screening/ScreeningSearchItemsSelectRecord.vue'

export default {
  name: 'ScreeningSearchItemsWrapper',
  components: {
    ScreeningSearchItems,
    ScreeningSearchItemsSelectRecord
  },
  props: {
    screeningSearchItems: {
      type: Array,
      required: true
    },
    screeningSearchItemsWrapperParams: {
      type: Object,
      required: true
    }
  },
  computed: {
    filterIcoGroup: {
      get () {
        if (this.$store.getters.getScreeningSearchObject.filterIcoGroup) {
          return this.$store.getters.getScreeningSearchObject.filterIcoGroup
        } else {
          return 0
        }
      },
      set (val) {
        let clone = JSON.parse(JSON.stringify(this.$store.getters.getScreeningSearchObject))
        clone.filterIcoGroup = val
        this.$store.commit('SET_SCREENING_SEARCH_OBJECT', clone)
      }
    },
    filterAddressSubstring: {
      get () {
        return this.$store.getters.getScreeningSearchObject.filterAddressSubstring || ''
      },
      set (val) {
        let clone = JSON.parse(JSON.stringify(this.$store.getters.getScreeningSearchObject))
        clone.filterAddressSubstring = val
        this.$store.commit('SET_SCREENING_SEARCH_OBJECT', clone)
        this.screeningSearchItemsWrapperParams.confirmRequestParamsEnabled = true
        if (!this.filterAddressSubstring || this.filterAddressSubstring.length < 3) {
          this.screeningSearchItemsWrapperParams.confirmRequestParamsEnabled = false
        } else {
          this.screeningSearchItemsWrapperParams.confirmRequestParamsEnabled = true
        }
      }
    }
  },
  watch: {
    filterIcoGroup (val) {
      this.$emit('screening-re-init')
    }
  },
  methods: {
    resetFilterAddress () {
      if (this.filterAddressSubstring) {
        if (this.filterAddressSubstring.length > 3) {
          this.filterAddressSubstring = ''
          this.$emit('screening-re-init')
        } else {
          this.filterAddressSubstring = ''
          this.$emit('screening-re-init')
        }
      }
    },
    redirectToScreening (item) {
      this.$emit('redirect-to-screening', item)
    },
    screeningReInit () {
      if (this.screeningSearchItemsWrapperParams.confirmRequestParamsEnabled) {
        this.$emit('screening-re-init')
      }
    }
  }
}
</script>
